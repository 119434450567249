<template>
  <div v-if="is_dev_env" class="my-2">
    <button class="btn btn-outline-warning btn-sm" @click="expand = !expand">{{ title }}</button>
    <pre v-if="expand" class="small border p-2 my-2"><slot>{{ value }}</slot></pre>
  </div>
</template>
<script>
// TODO: Toggle to remove lines that simply end objects and arrays
// TODO: Absolute pos "float above content" mode

export default {
  props: {
    title: {
      type: String,
      default: 'debug',
    },

    value: {
      type: [Object, Array],
      default: null,
    },
  },

  data() {
    return {
      expand: false,
    }
  },

  computed: {
    is_dev_env() {
      return window.location.host === 'localhost:3000'
    },
  },
}
</script>
